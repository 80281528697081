const gameName = {
  baccarat: 'บาคาร่า',
  roulette: 'รูเล็ต',
  blackjack: 'แบล็คแจ็ค',
  dragontiger: 'เสือมังกร',
  gameshow: 'เกมส์โชว์',
  highlow: 'ไฮโล',
}

const gameLink = {
  baccarat: 'Baccarat',
  roulette: 'Roulette',
  blackjack: 'Blackjack',
  dragontiger: 'DragonTiger',
  gameshow: 'GameShow',
  highlow: 'HighLow',
}

const gameType = {
  BACCARAT: 'baccarat',
  GAMESHOW: 'gameshow',
}

export { gameName, gameLink, gameType }
