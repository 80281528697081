import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { gamesApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { IGameItem } from '@pig-casino/types/Game.type'
import { SearchParamType } from '@pig-casino/types/Search.type'
import { camelizeKeys } from '@pig-frontend/utils'

interface ListGameHookType<SelectReturnType> {
  params?: SearchParamType
  select?: (data: IGameItem[]) => SelectReturnType
  key?: string[]
  enabled?: boolean
}

export default function useListGame<SelectReturnType = IGameItem[]>({
  params = {},
  key = [],
  select,
  enabled,
}: ListGameHookType<SelectReturnType>) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useQuery({
    queryKey: [gamesApi.key.list, ...key],
    queryFn: async () => {
      const res = await gamesApi.list({
        customerCode: isAuthenticated && customerCode,
        ...params,
      })
      return {
        ...res,
        data: camelizeKeys(res.data) as IGameItem[],
      }
    },
    // enabled,
    select: (data) => {
      return select ? select(data.data) : data.data || []
    },
    refetchOnWindowFocus: true,
  })
}
