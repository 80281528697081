import styled from 'styled-components'

const StyledSpacer = styled.div`
  height: 12px;
  @media (min-width: 768px) {
    height: 24px;
  }
`

const Spacer = () => {
  return <StyledSpacer />
}

export default Spacer
