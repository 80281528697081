import {
  ContactUs as UIKitContactUs,
  IContactUsProps,
} from '@pig-frontend/uikit'
import { placeholderImage } from '@pig-casino/constants/images'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { useRouter } from 'next/router'
import { contactUsConfig } from './config'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'

export default function ContactUs({
  className,
  ...props
}: Partial<IContactUsProps>) {
  const router = useRouter()
  const isAuthentication = useRecoilValue(authStateCommon.isAuthenState)

  const toggleFChatPanel = () => {
    if (!isAuthentication) {
      router.push(pageLinkCommon.login)
    } else {
      if (typeof window !== 'undefined' && window.fcWidget?.isInitialized()) {
        if (window.fcWidget?.isOpen()) {
          window.fcWidget?.close()
        } else {
          window.fcWidget?.open()
        }
      }
    }
  }

  return (
    <UIKitContactUs
      {...props}
      contacts={contactUsConfig({ fChatAction: toggleFChatPanel })}
      placeholderImage={placeholderImage}
      className={className}
    />
  )
}
