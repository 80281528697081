import { IContactUsProps } from '@pig-frontend/uikit'
import {
  contactUsFacebookImage,
  contactUsLineImage,
  contactUsTwitterImage,
  contactUsMessengerImage,
  LayoutChatIcon,
} from '@pig-casino/constants/images'
import styles from './index.module.scss'

export const contactUsConfig = ({
  fChatAction,
}): IContactUsProps['contacts'] => [
  {
    key: 'line',
    image: contactUsLineImage,
    title: 'PIGBET',
    text: 'LINE Official Account',
    onClick: () => window.location.assign('https://www.pigbet.app/line'),
  },
  {
    key: 'facebook',
    image: contactUsFacebookImage,
    title: 'PIGBET',
    text: 'Facebook Official',
    onClick: () => window.location.assign('https://www.pigbet.app/facebook'),
  },
  {
    key: 'messenger',
    image: contactUsMessengerImage,
    title: 'PIGBET',
    text: 'Messenger Official',
    onClick: () =>
      window.location.assign('https://www.pigbet.app/facebookmessenger'),
  },
  {
    key: 'twitter',
    image: contactUsTwitterImage,
    title: 'PIGBET',
    text: 'Twitter Official Account',
    onClick: () => window.location.assign('https://www.pigbet.app/twitter'),
  },
  {
    key: 'fchat',
    title: 'PIGBET',
    text: 'Webchat',
    icon: <LayoutChatIcon />,
    color: styles.colorPrimary1,
    onClick: fChatAction,
  },
]
